import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../helper/axios";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";

import TextInput from "../components/TextInput";
import MaskedInput2 from "../components/MaskedInput2";
import DropDownList from "../components/DropDownList";

const loginApi = axiosInstance;
function Signup(props) {
  const ApplyList = {
    "B":"BS/BE/B.Arch programs",
    "M":"MS Programs",
    "P":"PhD Programs"
  };
  let initError = {
    name: "",
    father: "",
    mobile: "",
    cnic: "",
    email: "",
    applyfor:"",
  };
  let initData = {
    name: "",
    father: "",
    mobile: "",
    cnic: "",
    email: "",
    applyfor:"",
  };

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState(initError);
  const [formdata, setFormData] = useState(initData);

  const [tcc, setTcc] = useState(false);
  const [haveInstructions, setHaveInstructions] = useState(false);
  const { setTitle, setOperation, user, setPLink, siteInfo } =
    useContext(UserContext);
  useEffect(() => {
    setTitle("");
    setOperation("");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  const reqCols = ["name", "father", "cnic", "mobile", "email" , "password", "password2", "applyfor"];
  const [formMeta] = useState({
    name: "Name",
    father: "Father",
    cnic: "CNIC / B-Form #",
    mobile: "Contact Number",
    email: "Email",
    password: "Password",
    password2: "Confirm Password",
    applyfor: "Applying For",
  });
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (siteInfo && siteInfo.instruction) {
      setHaveInstructions(true);
    }
  }, [siteInfo]);

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user, navigate]);

  function handelChange(e) {
    let value = e.target.value;
    let inputName = e.target.name;

    setFormData({ ...formdata, [inputName]: value });
    setErrorText(initError);
  }
  function acceptTerms(e) {
    setTcc(e.target.checked);
  }

  function restform(e) {
    e.preventDefault();
    setFormData(initData);
  }
  function signup(e) {
    e.preventDefault();

    const newErrors = {};
    reqCols.forEach((key) => {
      if (!formdata[key]) {
        newErrors[key] = `${formMeta[key]} cannot be blank.`;
      }
    });

    setErrorText(newErrors);
    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form", "error");
      return;
    }

    if(formdata.password !== formdata.password2){
      newErrors['password2'] = "Confirm password do not match";
      setErrorText(newErrors);
      notify("Error Validating the Form", "error");
      return;
    }
    setLoading(true);
    var formData = new FormData();
    formData.append("SignupForm[name]", formdata.name);
    formData.append("SignupForm[father]", formdata.father);
    formData.append("SignupForm[email]", formdata.email);
    formData.append("SignupForm[uni_id]", siteInfo.app_id);
    formData.append("SignupForm[tcc]", "1");
    formData.append("SignupForm[cnic]", formdata.cnic);
    formData.append("SignupForm[mobile]", formdata.mobile);
    formData.append("SignupForm[applyfor]", formdata.applyfor);
    formData.append("SignupForm[password]", formdata.password);
    formData.append("SignupForm[password2]", formdata.password2);

    loginApi
      .post("/signup", formData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            notify(res.data.message, "error");
            setErrorText(res.data.errors);
          } else {
            setErrorText(initError);
            notify(res.data.message, "success");
            navigate("/login");
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify(err.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createMarkup() {
    if (siteInfo) {
      let html = "<h3 class='text-danger '>" + Trans("Instructions") + "</h3>";
      html += siteInfo.instruction;

      return { __html: html };
    }
    return { __html: "" };
  }

  return (
    <>
      {siteInfo && siteInfo.allow_signup ? (
        <div className="auth-layout-wrap">
          <div
            className="auth-content"
            style={haveInstructions ? { maxWidth: "600px" } : {}}
          >
            <div className="card o-hidden">
              <div className="p-4">
                <div className="site-login">
                  <h3>{Trans("Applicant Signup")}</h3>

                  <p>
                    {Trans("Please fill out the following fields to signup:")}
                  </p>

                  <div className="row">
                    <div
                      className={haveInstructions ? "col-md-6" : "col-md-12"}
                    >
                      <form id="login-form" data-gtm-form-interact-id="0">
                        <TextInput
                          name="name"
                          label={formMeta.name}
                          value={formdata.name}
                          length={100}
                          handleInputChange={handelChange}
                          reqCols={reqCols}
                          errors={errorText}
                          placeholder="Majid Hanif"
                        />
                        <TextInput
                          name="father"
                          label={formMeta.father}
                          value={formdata.father}
                          length={100}
                          handleInputChange={handelChange}
                          reqCols={reqCols}
                          errors={errorText}
                          placeholder="Muhammad Hanif"
                        />
                        <TextInput
                          name="email"
                          label={formMeta.email}
                          value={formdata.email}
                          length={100}
                          handleInputChange={handelChange}
                          reqCols={reqCols}
                          errors={errorText}
                          placeholder={"yourname@example.com"}
                        />

                        <MaskedInput2
                          mask="11111-1111111-1"
                          name="cnic"
                          label={formMeta.cnic}
                          value={formdata.cnic}
                          length={20}
                          handleInputChange={handelChange}
                          reqCols={reqCols}
                          errors={errorText}
                        />
                        <p className="text-info">
                          {Trans("CNIC Like 3XXXX-9XXXX4X-X")}
                        </p>
                        <TextInput
                          name="mobile"
                          label={formMeta.mobile}
                          value={formdata.mobile}
                          length={20}
                          placeholder={"+923003323434"}
                          handleInputChange={handelChange}
                          reqCols={reqCols}
                          errors={errorText}
                        />
                        <DropDownList
                          name="applyfor"
                          label={formMeta.applyfor}
                          value={formdata.applyfor}
                          handleInputChange={handelChange}
                          reqCols={reqCols}
                          prompt={"-Select-"}
                          listData={ApplyList}
                          errors={errorText}
                        />
                        <TextInput
                          name="password"
                          type="password"
                          label={formMeta.password}
                          value={formdata.password}
                          length={15}
                          handleInputChange={handelChange}
                          reqCols={reqCols}
                          errors={errorText}
                        />
                        <TextInput
                          name="password2"
                          type="password"
                          label={formMeta.password2}
                          value={formdata.password2}
                          length={15}
                          handleInputChange={handelChange}
                          reqCols={reqCols}
                          errors={errorText}
                        />

                        <label className="checkbox checkbox-primary">
                          <input
                            type="checkbox"
                            value={tcc}
                            onChange={acceptTerms}
                          />
                          <span className="checkmark"></span>
                          <Link
                            to="/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {Trans("Accept Terms & Conditions")}
                          </Link>
                        </label>

                        <div className="form-group mt-3">
                          {loading ? (
                            <DataLoading loadTxt={Trans("Signing up.....")} />
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-primary"
                                name="login-button"
                                onClick={signup}
                                disabled={!tcc}
                              >
                                {Trans("Signup")}
                              </button>
                              <button
                                className="btn btn-danger ml-2"
                                name="login-button"
                                onClick={restform}
                              >
                                {Trans("Rest Form")}
                              </button>
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6">
                      <div className="pl-1">
                        <div dangerouslySetInnerHTML={createMarkup()} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-danger text-16">  You cannot sign up at the moment; this functionality has been disabled by the university.</p>
      )}
    </>
  );
}

export default Signup;
